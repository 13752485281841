<template>
  <div
    class="d-flex justify-content-center align-items-center flex-column payment-success text-center"
  >
    <img
      src="../../../assets/images/payment/celebration.png"
      alt="illustration"
      class="img-fluid w-10"
    />
    <h1>
      {{ $t("Verification succesfull") }}
    </h1>
    <p class="mb-0 text-gray mw-500">
      {{ $t("We have successfully verified your income and identity.") }}
      <br class="d-none d-sm-block" />
      {{
        $t("You will be automatically redirected to the profile page in a few moments")
      }}
      <br class="d-none d-sm-block" />
      {{ $t("do not close this page in the meantime") }}
    </p>
    <button class="btn btn-primary mt-4" @click="$router.replace(`/user/profile`)">
      {{ $t("Complete verification and share my data") }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {
    // setTimeout(() => {
    // this.$router.replace(`/user/profile`);
    // }, 3000);
  },
};
</script>

<style lang="scss">
body {
  background-color: #ffff !important;
}

.payment-success {
  height: calc(100vh - 50px);
}
</style>
